import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const ClockerContentData: ContentData[] = [
    {
        id: 1,
        title: `Universal directory`,
        desc: `Integrate with apps with our directory and meta directory made to ease up lifecycle management. Customize your user directory by user profile, contacts, devices, groups users and more.`,
        imageUrl: `images/lifecycle-universal-directory.svg`,
    },
    {
        id: 2,
        title: `App Integration`,
        desc: ` Integrate with over a 100 applications. Create user groups and synchronize with your preferred HR system or application including G Suite, Bamboo HR, and more. `,
        imageUrl: `images/lifecycle-app-integration.svg`,
    },
    {
        id: 3,
        title: `Onboarding/Offboarding and provision`,
        desc: `Add and remove users, grant access or revoke entitlement to users, create a quick custom onboarding and process while monitoring their activities. Deauthorize and off board users within seconds to prevent security breach & unauthorized access to data from previous employees.`,
        imageUrl: `images/lifecycle-on-off-provision.svg`,
    },
];

const Clocker = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Lifecycle 
                Management"
                desc="Manage and automate the lifecycle of your work process with real time synchronization. Use our StackIAM to coordinate and restrict the identification, access and governance of identities for access to your business tools and information."
                buttonText="Get Started"
                imageUrl="/images/lifecycle-hero@3x.png"
            />
            <HeadingSection
                heading="Features"
                subheading="Lifecycle 
                Management"
            />
            {ClockerContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default Clocker;
